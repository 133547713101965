/* .container {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1000;
  transform: rotateZ(25%);
}*/
.loading {
  position: absolute;
  width: 70px;
  z-index: 1000;
  top: 50%;
  left: 50%;
  /* background-color: blue; */
  transform: translateX(-50%) translateY(-50%);
}
.spin {
  /* animation-name: rotate; */
  animation-duration: 2s !important;
  animation-iteration-count: infinite !important;
  animation-timing-function: linear !important;
  animation-fill-mode: forwards !important;
  transform-origin: 50% !important;
}
circle {
  fill: #2d3e4e;
  /* fill: red; */
  animation-name: blink !important;
  animation-duration: 2s !important;
  animation-iteration-count: infinite !important;
  animation-timing-function: linear !important;
  animation-fill-mode: forwards !important;
  opacity: 0;
}
.c1 {
  animation-delay: 0s !important;
}
.c2 {
  animation-delay: 0.125s !important;
}
.c3 {
  animation-delay: 0.25s !important;
}
.c4 {
  animation-delay: 0.375s !important;
}
.c5 {
  animation-delay: 0.5s !important;
}
.c6 {
  animation-delay: 0.625s !important;
}
.c7 {
  animation-delay: 0.75s !important;
}
.c8 {
  animation-delay: 0.875s !important;
}
.c9 {
  animation-delay: 1s !important;
}
.c10 {
  animation-delay: 1.125s !important;
}
.c11 {
  animation-delay: 1.25s !important;
}
.c12 {
  animation-delay: 1.375s !important;
}
.c13 {
  animation-delay: 1.5s !important;
}
.c14 {
  animation-delay: 1.625s !important;
}
.c15 {
  animation-delay: 1.75s !important;
}
.c16 {
  animation-delay: 1.875s !important;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg) !important;
  }
}
