@font-face {
  font-family: 'Goosebreak';
  src: local('Goosebreak'), url(./fonts/Goosebreak.woff2) format('woff2'),
    url(./fonts/Goosebreak.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Light.ttf) format('truetype');
  font-display: swap;
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Italic.ttf) format('truetype');
  font-display: swap;
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url(./fonts/Roboto/Roboto-LightItalic.ttf) format('truetype');
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Bold.ttf) format('truetype');
  font-display: swap;
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
    url(./fonts/Roboto/Roboto-BoldItalic.ttf) format('truetype');
  font-display: swap;
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
    url(./fonts/MaterialsIcons.woff2) format('woff2');
  font-display: swap;
}

/* font-family: 'Pacifico';
font-style: normal;
font-weight: 400;
src: local('Pacifico Regular'), local('Pacifico-Regular'), url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2) format('woff2');
font-display: swap; */

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

/* .preload * {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  animation-duration: 0s !important;
  -webkit-animation-duration: 0s !important;
  -moz-animation-duration: 0s !important;
  -o-animation-duration: 0s !important;
  animation-delay: 0s !important;
  -webkit-animation-delay: 0s !important;
  -moz-animation-delay: 0s !important;
  -o-animation-delay: 0s !important;
  transition-delay: 0s !important;
  -webkit-transition-delay: 0s !important;
  -moz-transition-delay: 0s !important;
  -o-transition-delay: 0s !important;
} */

.preload * :not([name='loading'] > *) {
  transition: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
  animation-duration: 0s !important;
  -webkit-animation-duration: 0s !important;
  -moz-animation-duration: 0s !important;
  -o-animation-duration: 0s !important;
  animation-delay: 0s !important;
  -webkit-animation-delay: 0s !important;
  -moz-animation-delay: 0s !important;
  -o-animation-delay: 0s !important;
  transition-delay: 0s !important;
  -webkit-transition-delay: 0s !important;
  -moz-transition-delay: 0s !important;
  -o-transition-delay: 0s !important;
}

/* .preload * {
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  animation-duration: 0s;
  -webkit-animation-duration: 0s;
  -moz-animation-duration: 0s;
  -o-animation-duration: 0s;
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  transition-delay: 0s;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
} */

html,

/* body {
    height: 100%;
} */

body {
  width: 100vw;
  height: 100% !important;
  /* max-width: 100vw; */
  /* max-height: 100vh; */
  /* min-height: -webkit-fill-available; */
  /* background-color: #ffffff; */
  /* border-color: red; */
  /* background-color: var(--background-color); */
  background-color: var(--background-color);
  color: var(--color);
  background: var(--background);
  overflow: hidden !important;
  position: fixed;
  -webkit-overflow-scrolling: touch;

  /* height: 100%; */
  /* background: radial-gradient( 53.85% 55% at 51.09% 63.8%, rgba(0, 0, 0, 0) 32.31%, #000000 100%), #2d3e4e; */
  /* opacity: calc(var(--nav-slide-opacity) * 1.5); */
}

.app {
  height: 100%;
}

body:after {
  background-image: url('./components/Images/Fullscreen.svg');
}

a {
  color: #3c4144;
  text-decoration: underline;
  transition: 0.5s;
}

a:hover {
  color: #3c5268;
}

img {
  width: 100%;
}

button:focus {
  outline: none;
}

.navCluster {
  display: block;
  position: absolute;
  top: 0;
}

p {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.125em;
  line-height: 24px;
}

h1 {
  font-family: 'Goosebreak', serif;
  font-style: normal;
  font-weight: normal;
  /* font-size: 120px; */
  /* line-height: 152px; */
  /* letter-spacing: 0.05em; */
  /* color: #2d3e4e; */
  /* color: var(--primary-color); */
}

h2 {
  font-family: Roboto, sans-serif;
  font-style: italic;
  font-weight: 200;
  /* font-size: 74px; */
  /* line-height: 87px; */
  /* color: #2d3e4e; */
  /* color: red; */
  /* color: var(--primary-color); */
}

h3 {
  font-family: 'Goosebreak', serif;
  font-style: normal;
  font-weight: 300;
  font-size: 1.5em;
  line-height: 30px;
  /* color: #2d3e4e; */
}

.navCluster {
  /* display: inline-block; */
  position: fixed;
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: flex-start;
  align-items: center;
  top: 0;
  width: 20vw;
  margin: 0 auto;
  z-index: 11;
  height: 100px;
}

model-viewer {
  --progress-bar-color: #ffd04e;
  --progress-bar-height: 8px;
  --poster-color: transparent;
  width: 100%;
  height: 100%;
  --progress-mask: transparent;
}

/* :not(:defined) > :not(.poster) {
  display: none;
  visibility: hidden;
} */

:root {
  --primary-color: #2d3e4e;
  --secondary-color: #2d3e4e;
  --tertiary-color: #2d3e4e;
  --quaternary-color: #2d3e4e;
  --background-color: #ffffff;
  --h1-color: #2d3e4e;
  --background: white;
  --nav-background: white;
}

/* ::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: #2d3e4e;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    background: transparent;
} */

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(60, 65, 68, 0.2);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(60, 65, 68, 0.8);
}

.introStyle {
  background: #d7e7d4 !important;
}

.historyStyle {
  background: #ffe5a1 !important;
}

.materialsStyle {
  background: #e7d9ba !important;
}

.designStyle {
  background: #ffddbe !important;
}

.storiesStyle {
  background: #fff4d6 !important;
}

.mainStyle {
  background: var(--background);
}
