.menuContainer {
  /* background-color: white; */
  /* display: flex; */
  position: absolute;
  /* height: 10vh; */
  top: 6px;
  /* left: 0; */
  z-index: 9999;
  /* border: 2px solid red; */
  opacity: calc(var(--nav-slide-opacity) * 1.5);
  min-height: 45px;
}

.titleAudioGrouping {
  width: max-content;
  display: flex;
  justify-content: space-between;
}

.linkBox {
  display: flex;
  justify-content: space-around;
  max-width: 50%;
  width: 50%;
  text-align: center;
}

.chevron {
  /* background-color: green; */
  display: inline-block;
  transition: 0.5s;
  /* background-image: url("../Images/Arrow.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; */
  mask-image: url('../Images/Arrow.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center;
  background-color: var(--primary-color);
  width: 20px;
  height: 20px;
  right: 0;
  bottom: 50%;
  /* position: absolute; */
  margin-left: 12px;
  margin-right: 12px;
  /* border: 1px solid blue; */
  opacity: 0.5;
  align-self: center;
}

.chevronActive {
  transform: rotate(180deg);
}

.chevronInactive {
  transform: rotate(0deg);
}

.openbtn {
  position: relative;
  cursor: pointer;
  /* background-color: transparent; */
  color: #2d3e4e;
  padding: 1px 9px 12px 1px;
  /* border: none; */
  z-index: 9900;
  transition: 0.3s;
  font-family: 'Goosebreak', serif;
  font-style: italic;
  font-weight: bold;
  font-size: 35px;
  line-height: 35px;
  background-color: transparent;
  height: 100%;
  /* border: 1px solid blue; */
  border: none;
  display: flex;
}

.openbtn:hover h1 {
  color: var(--tertiary-color);
  opacity: 1;
}

.openbtn:hover h2 {
  color: var(--tertiary-color);
  opacity: 1;
}

.openbtn h1 {
  transition: 0.5s;
  display: inline-block;
  font-style: italic;
  font-weight: bold;
  font-size: 35px;
  line-height: 35px;
  color: var(--primary-color);
  opacity: calc(var(--nav-slide-opacity) * 1.5);
  padding: 15px 5px 15px 15px;
}

.openbtn h2 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  position: absolute;
  /* right: 53px; */
  color: var(--primary-color);
}

.openbtn:hover {
  /* background-color: #444; */
  /* text-shadow: 0px 0px 2px #2d3e4e; */
}

.openbtn:hover > .subtitle {
  opacity: 1;
  right: 85px;
}

/* The sidepanel links */

.navItem {
  /* padding: 8px 8px 8px 32px; */
  text-decoration: none;
  font-size: 10px;
  color: #818181;
  /* display: flex; */
  transition: 0.25s;
  /* border: 1px solid black; */
  /* margin: 0px 0px; */
  align-content: center;
  /* justify-content: center; */
  position: absolute;
  top: 0;
  /* width: 100px; */
  height: 100%;
  /* text-align: center; */
  background-color: transparent;
  transition-timing-function: linear;
  /* padding: 5%; */
  overflow: hidden;
  /* flex-shrink: 0; */
  /* margin: 0 auto; */
  /* left: 0; */
  /* right: 0; */
  direction: rtl;
  opacity: calc(var(--nav-slide-opacity) * 1.5);
}

.navItem:hover {
  opacity: 1;
}

.navItem:hover .navIcon {
  background-color: var(--tertiary-color);
}

.navItem:hover h2 {
  color: var(--tertiary-color);
}

.navItem h2 {
  text-align: center;
  position: absolute;
  bottom: 20%;
  /* top: 0; */
  margin: 0 auto;
  float: right;
  right: 0;
  left: 0;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 18px;
  color: var(--primary-color);
  font-feature-settings: 'pnum' on, 'lnum' on;
  /* border: 1px solid blue; */
  /* width: 100px; */
  width: 100px;
  height: 30%;
  transition: 0.5s;
}

.openbtn .subtitle {
  opacity: 0;
  transition: 0.5s;
  right: 110px;
  bottom: 10px;
}

.navIcon {
  width: 100px !important;
  height: 50%;
  top: 0;
  /* background-color: transparent;
    background-position: center;
    background-repeat: no-repeat; */
  /* background-size: contain; */
  position: absolute;
  padding: 10px;
  /* margin: 20% 10%; */
  /* margin-bottom: 20%; */
  /* overflow: hidden; */
  margin: 0 auto;
  left: 0;
  right: 0;
  float: left;
  /* Mask implementation */
  background: var(--primary-color);
  /* background-size: 90%; */
  /* background-color: red; */
  border: 5px solid transparent;
  /* display: block; */
  mask-position: center;
  mask-repeat: no-repeat;
  /* mask-size: contain; */
  transition: 0.5s;
}

.navActive {
  /* padding: 8px 8px 8px 32px; */
  text-decoration: none;
  font-size: 10px;
  /* color: #818181; */
  /* display: flex; */
  /* border: 1px solid black; */
  /* margin: 0px 0px; */
  /* justify-content: center; */
  position: relative;
  top: 0;
  /* width: 100px; */
  height: 100%;
  /* text-align: center; */
  background-color: transparent;
  transition-timing-function: linear;
  /* padding: 5%; */
  overflow: hidden;
  /* flex-shrink: 0; */
  /* margin: 0 auto; */
  /* left: 0; */
  /* right: 0; */
  padding: 5px 15px 5px 15px;
  display: block;
  border: 1px solid orange;
  text-align: center;
  width: 55px;
  color: red;
}

@media only screen and (max-width: 1175px) {
  .navActive {
    /* padding: 8px 8px 8px 32px; */
    text-decoration: none;
    font-size: 10px;
    color: #818181;
    /* display: flex; */
    /* border: 1px solid black; */
    /* margin: 0px 0px; */
    /* justify-content: center; */
    position: relative;
    top: 0;
    /* width: 100px; */
    height: 100%;
    /* text-align: center; */
    background-color: transparent;
    transition-timing-function: linear;
    /* padding: 5%; */
    overflow: hidden;
    /* flex-shrink: 0; */
    /* margin: 0 auto; */
    /* left: 0; */
    /* right: 0; */
    padding: 5px 15px 5px 15px;
    display: block;
    border: 1px solid blueviolet;
    text-align: center;
    width: 55px;
  }
  .menuContainer {
    /* background-color: white; */
    /* display: flex; */
    position: absolute;
    /* height: 10vh; */
    top: 0;
    /* left: 0; */
    z-index: 9999;
    width: 100vw;
    /* border: 2px solid red; */
    height: 5.94vh;
    display: flex;
    justify-content: space-between;
    text-align: center;
    vertical-align: middle;
    /* background: linear-gradient( 90deg, #fcfbfa 3.28%, #f9f8f6 30.68%, #f7f5f2 68.17%, #fcfbfa 95.57%); */
    background: var(--nav-background);
    background-color: var(--secondary-color);
    border-bottom: 0.0926vh solid #bbbfb0;
    opacity: 1;
    height: 45px;
  }
  .openbtn {
    position: relative;
    /* cursor: pointer; */
    /* background-color: transparent; */
    color: #2d3e4e;
    padding: 0px 9px 0px 17px;
    /* border: none; */
    z-index: 9900;
    transition: 0.3s;
    font-family: 'Goosebreak', serif;
    font-style: italic;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    background-color: transparent;
    /* border: 1px solid blue; */
    /* border: none; */
    height: 100%;
    cursor: auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .openbtn h1 {
    font-size: 16px;
    line-height: 20px;
    /* border: 1px solid red; */
    color: var(--primary-color);
    padding: 0px;
  }
  .openbtn h2 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    position: relative;
    /* border: 1px solid blue; */
    color: var(--primary-color);
    width: 100%;
    margin-left: 0px;
    text-align: right;
    /* opacity: var(--); */
  }
  .openbtn:hover {
    /* background-color: #444; */
    /* text-shadow: 0px 0px 2px #2d3e4e; */
  }
  .openbtn:hover > .subtitle {
    opacity: 1;
    right: 0;
  }
  /* The sidepanel links */
  .navItem {
    /* padding: 8px 8px 8px 32px; */
    text-decoration: none;
    font-size: 10px;
    /* color: #818181; */
    /* display: flex; */
    /* border: 1px solid black; */
    /* margin: 0px 0px; */
    /* justify-content: center; */
    position: relative;
    /* top: 0; */
    /* width: 100px; */
    height: 100%;
    /* text-align: center; */
    background-color: transparent;
    transition-timing-function: linear;
    /* padding: 5%; */
    overflow: hidden;
    /* flex-shrink: 0; */
    /* margin: 0 auto; */
    /* left: 0; */
    /* right: 0; */
    padding: 5px 15px 5px 15px;
    display: block;
    text-align: center;
    width: 55px;
  }
  .navItem h2 {
    opacity: 0.45;
    font-weight: normal;
    position: absolute;
    font-size: 13px;
    /* line-height: 30px; */
    color: var(--primary-color);
    /* border: 1px solid blue; */
    /* width: 100px; */
    /* width: max-content; */
    height: max-content;
    display: inline-block;
    text-align: center;
    width: min-content;
    transition: 0.5s;
    top: 50%;
    transform: translateY(-50%);
  }
  .navItem h2:active {
    opacity: 1;
    font-weight: normal;
    position: absolute;
    font-size: 13px;
    line-height: 15px;
    color: var(--primary-color);
    /* border: 1px solid blue; */
    /* width: 100px; */
    /* width: max-content; */
    height: max-content;
    display: inline-block;
    text-align: center;
    width: min-content;
  }
}

@media only screen and (max-width: 350px) {
  .openbtn {
    display: none;
  }
  .titleAudioGrouping {
    display: none;
  }
  .linkBox {
    display: flex;
    justify-content: space-around;
    max-width: 100%;
    width: 100%;
    text-align: center;
  }
  /* .navItem {
    font-size: 8px;
  }
  .navItem h2 {
    font-size: 10px;
    line-height: 12px;
  }
  .navItem h2:active {
    font-size: 10px;
    line-height: 12px;
  }
  .openbtn h1 {
    font-size: 13px;
    line-height: 16px;
  }
  .openbtn h2 {
    font-size: 8px;
    line-height: 9.6px;
  }
  .openbtn {
    padding: 0px 7px 0px 8px;
  } */
}
