.tooltipContainer {
  position: absolute !important;
  display: block;
  background-color: var(--primary-color) !important;
  /* top: 50%;
  right: 100%;
  transform: translateY(-50%); */
  opacity: 1;
  animation: hideTutorial;
  animation-delay: 20s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

@keyframes hideTutorial {
  0% {
    opacity: 1 !important;
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}

.tooltipContainer.up {
  top: calc(100% + 26px);
  right: 50%;
  transform: translate(50%);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.tooltipContainer.right {
  top: 50%;
  right: calc(100% + 26px);
  transform: translateY(-50%);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.tooltipContainer.down {
  bottom: calc(100% + 26px);
  right: 50%;
  transform: translate(50%);
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
  border-radius: 5px;
}

.tooltipContainer.left {
  top: 50%;
  left: calc(100% + 26px);
  transform: translateY(-50%);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.tooltipInner {
  /* width: 120px;
  height: 80px; */
  /* background: red; */
  position: relative;
  /* padding: 5%; */
  overflow-y: auto;
  overflow-x: hidden;
  direction: rtl;
  text-align: left;
  border-radius: 5px;
  padding: 0px !important;
  margin: 0px !important;
}

.tooltipInner p {
  padding: 15px !important;
  direction: ltr;
  color: var(--secondary-color) !important;
  font-family: 'Roboto', sans-serif !important;
  font-style: normal;
  font-weight: 300;
  font-size: 16px !important;
  line-height: 24px;
  margin: 0px !important;
  white-space: normal !important;
}

.tooltipInner.up {
  width: max-content;
  max-width: 15vw;
  min-width: min-content;
}

.tooltipInner.right {
  width: max-content;
  max-width: 15vw;
  height: auto;
  /* max-height: vh; */
  min-width: min-content;
}

.tooltipInner.down {
  width: max-content;
  max-width: 15vw;
  min-width: min-content;
}

.tooltipInner.left {
  width: max-content;
  max-width: 15vw;
  height: auto;
  /* max-height: 15vh; */
  min-width: min-content;
}
.pointLeft {
  content: '';
  position: absolute;
  right: calc(100% - 1px);
  top: 0px;
  width: 1px;
  height: 0;
  /* border-top: 40px solid transparent; */
  border-right: 26px solid var(--primary-color);
  /* border-bottom: 40px solid transparent; */
}

.pointRight {
  content: '';
  position: absolute;
  left: calc(100% - 1px);
  top: 0px;
  width: 0px;
  height: 10px;
  /* border-top: 40px solid transparent; */
  border-left: 26px solid var(--primary-color);
  /* border-bottom: 40px solid transparent; */
}

.pointUp {
  content: '';
  position: absolute;
  bottom: calc(100% - 1px);
  /* top: 0px; */
  width: 0;
  height: 0;
  border-bottom: 26px solid var(--primary-color);
  /* border-left: 60px solid transparent;
  border-right: 60px solid transparent; */
}

.pointDown {
  content: '';
  position: absolute;
  top: calc(100% - 1px);
  /* top: 0px; */
  width: 0;
  height: 0;
  border-top: 26px solid var(--primary-color);
  /* border-left: 60px solid transparent;
  border-right: 60px solid transparent; */
  right: 50%;
  transform: translateX(50%);
}

.pointNone {
  display: none;
}

@media only screen and (max-width: 1175px) {
  .tooltipInner.up {
    width: max-content;
    max-width: 15vw;
    min-width: min-content;
  }

  .tooltipInner.right {
    width: max-content;
    max-width: 50vw;
    height: auto;
    max-height: max-content;
    min-width: min-content;
  }

  .tooltipInner.down {
    width: max-content;
    max-width: 80vw;
    min-width: min-content;
  }

  .tooltipInner.left {
    width: max-content;
    max-width: 15vw;
    height: auto;
    max-height: 15vh;
    min-width: min-content;
  }
}

@media only screen and (max-width: 500px) {
  .tooltipInner p {
    padding: 10px !important;
    direction: ltr;
    color: var(--secondary-color) !important;
    font-family: 'Roboto', sans-serif !important;
    font-style: normal;
    font-weight: 300;
    font-size: 13px !important;
    line-height: 19px;
    white-space: normal !important;
  }
}

/* @media only screen and (max-height: 1175px) {
  .tooltipInner.up {
    width: max-content;
    max-width: 15vw;
    min-width: min-content;
  }

  .tooltipInner.right {
    width: max-content !important;
    max-width: 50vw !important;
    height: auto;
    max-height: max-content;
    min-width: min-content;
  }

  .tooltipInner.down {
    width: max-content;
    max-width: 15vw;
    min-width: min-content;
  }

  .tooltipInner.left {
    width: max-content;
    max-width: 15vw;
    height: auto;
    max-height: 15vh;
    min-width: min-content;
  }
} */
