/* style for the map radio button */

.mapIcon {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 35px;
  text-transform: none;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.moreIcon {
  /* Arrow Icon that exposes items in mobile view */
  font-family: 'Material Icons';
  /* font-weight: normal; */
  font-style: bold;
  font-size: 30px;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  position: relative;
  margin-top: -10px;
  margin-right: 0px;
  transition: 0.5s;
}

/* .moreIcon:hover{
    transition: 0.5s;
    left: 3px;
} */

.RadioButton {
  /* Fixes alignment when there is no text */
  vertical-align: top;
  cursor: pointer;
  transition: 0.5s;
  /* Size */
  /* width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px; */
  width: 50.54px;
  height: 50.54px;
  min-width: 31px;
  min-height: 31px;
  display: inline-block;
  /* Circle */
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  /* Background (If needed) */
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  /* Text */
  color: var(--primary-color);
  text-align: center;
  font-weight: bolder;
  margin-left: 10.5px;
  margin-right: 10.5px;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=60)';
  /* IE 8 */
  filter: alpha(opacity=60);
  /* IE 5-7 */
  opacity: 0.6;
  pointer-events: all;
  background-color: var(--secondary-color);
}

.RadioButton:hover {
  transition: 0.5s;
  color: var(--primary-color);
  /* background-color: #2d3e4e; */
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  /* IE 8 */
  filter: alpha(opacity=100);
  /* IE 5-7 */
  opacity: 1;
  border: 2px solid var(--primary-color);
}

.RadioButtonActive {
  color: var(--secondary-color);
  background-color: var(--primary-color);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  /* IE 8 */
  filter: alpha(opacity=100);
  /* IE 5-7 */
  opacity: 1;
}

.RadioButtonActive:hover {
  color: var(--secondary-color);
  background-color: var(--primary-color);
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
  /* IE 8 */
  filter: alpha(opacity=100);
  /* IE 5-7 */
  opacity: 1;
}

.iconText {
  /* font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 19px; */
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 29px;
}

@media only screen and (max-width: 1175px) {
  .RadioButton {
    margin-left: 1px;
    margin-right: 1px;
    width: 28px;
    height: 28px;
  }
  .iconText {
    font-size: 15px;
    line-height: 15px !important;
    /* line-height: 100%; */
  }
  .mapIcon {
    font-size: 20px;
  }
}
